.rc-tabs-tab {
    background-color: #fff;
}
.rc-tabs-nav-wrap {
    background-color: #fff;
}
.rc-tabs-tab-active,
.rc-tabs-tab-active {
    color: #007bff !important;
    background-color: white;
    border-bottom: 2px solid;
}
.rc-tabs-ink-bar {
    background-color: #007bff;
}

.rc-tabs-top .rc-tabs-tab {
    margin: 0px;
    padding: 10px 50px 10px 50px;
    color: #000000;
}
.rc-tabs-tab:hover {
    color: green;
}
.rc-tabs-top {
    border-bottom: none;
}

.rc-tabs-bottom .rc-tabs-tab {
    padding-left: 30px;
    padding-right: 30px;
}
